import { Directive, EventEmitter, Input, Output } from '@angular/core';

const rotate: { [key: string]: 'asc' | 'desc' | '' } = { asc: 'desc', desc: '', '': 'asc' };

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class SortableHeaderDirective {

  @Input() sortable: string = '';
  @Input() direction: 'asc' | 'desc' | '' = '';
  @Output() sort = new EventEmitter<{ column: string, direction: string }>();

  constructor() { }

  rotate(): void {
    this.direction = rotate[this.direction];
    this.sort.emit({ direction: this.direction, column: this.sortable });
  }

}
