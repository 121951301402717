import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  AfterViewInit,
  ViewChild, OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentfulService } from '../../service/contentful.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NationalbrandService } from '../../service/national-brands.service';
import { BusinessNameService } from '../../service/businessName.service';
import { AnalyticsService } from '../../service/google-analytics.service';
import { Subscription, combineLatest } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { ArticleUtilsService } from "../../service/article-utils.service";
import { OwnerFamilyService } from "../../service/owner-family.service";
import { TagType } from "../../models/product.interface";
import { OwnerService } from "../../service/owner-logo.service";

@Component({
  selector: 'app-pet-article-page',
  templateUrl: './pet-article-page.component.html',
  styleUrls: ['./pet-article-page.component.css'],
})
export class PetArticlePageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('bodyHtmlContainer') bodyHtmlContainer!: ElementRef;
  @ViewChild('videoElement') videoElementRef!: ElementRef;
  slug!: any;
  entryId!: any;
  contentfulEntryData: any | null = null;
  articleImageUrl!: string;
  bodyHtml!: string;
  title: string = '';
  subtitle: string = '';
  author: string = '';
  // ctaEndOfArticleImageAndText: string = '';
  routerSubscription!: Subscription;
  cardsData: any[] = [];
  errorMessage: string = '';
  isLoadingContentfulData: boolean = true;
  isLoadingCardsData: boolean = true;
  disabledBrands: any[] = [];
  ctaEndOfArticleImageAndText: any[] = []; // assuming this gets populated from your existing code
  filteredCTAs: any[] = [];
  filteredProductCTAs: any[] = [];
  showErrorBanner = false;
  legalName: string | null = null;
  ctaEndOfArticleProductAndService: any[] = [];
  daysSinceAdoption: number = 0;
  brandingDays: number = 0;
  shelterBrandingDays!: number;
  petLifeStages: string[] = [];
  petId: string | null = null;
  petInfo!: any;
  shelterInfo!: any;
  ownerInfo!: any;

  private managePetInfoSubs!: Subscription;
  private manageOtherSubs!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private contentfulService: ContentfulService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private brand: NationalbrandService,
    private businessNameService: BusinessNameService,
    private router: Router,
    private renderer: Renderer2,
    private branding: BusinessNameService,
    private logger: NGXLogger,
    private articleUtilsService: ArticleUtilsService,
    private ownerFamilyService: OwnerFamilyService,
    private ownerService: OwnerService,
  ) {

  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.slug = params.get('id');

      if (this.slug) {
        this.petId = localStorage.getItem('petszelAnimalId');
        if (this.petId) {
          this.isLoadingContentfulData = true;

          this.manageOtherSubs = combineLatest({
            shelter: this.ownerService.getBranding(),
            owner: this.ownerFamilyService.getOwnerInfo()
          }).subscribe({
            next: response => {
              this.shelterInfo = response.shelter;
              this.ownerInfo = response.owner;
              this.getPetInfo();
              this.trackCurrentPageView();
            }
          });
        } else {
          this.logger.error('petszelAnimalId is not defined.');
        }
      } else {
        this.logger.error('slug is not defined.');
      }

      this.scrollToTop();
    });

    this.businessNameService.legalName$.subscribe((name) => {
      this.legalName = name;
      if (this.contentfulEntryData) {
        this.processContent();
      }
    });
    
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.listenToButtonClicks();
    this.branding.shelterBrandingDays$.subscribe((days) => {
      this.shelterBrandingDays = days;
    });
    this.shelterBrandingDays =
      this.branding.shelterBrandingDaysSubject.getValue();

      if (this.videoElementRef) {
        const video: HTMLVideoElement = this.videoElementRef.nativeElement;
        video.addEventListener('loadeddata', () => {
          let canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          let ctx = canvas.getContext('2d');
          ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
          let image = document.getElementById('stillFrame') as HTMLImageElement;
          image.src = canvas.toDataURL();
          image.style.display = 'block';
        });
      }
      this.addClickTrackingToCTAs();

    }

  // Method to track the current page view
  trackCurrentPageView() {
    const ownerId = this.analyticsService.getOwnerId();
    const pagePath = this.router.url;
    if (ownerId) {
      this.analyticsService.trackPageView();
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.managePetInfoSubs) { this.managePetInfoSubs.unsubscribe(); }
    if (this.manageOtherSubs) { this.manageOtherSubs.unsubscribe(); }
  }

  // Method to replace {{sheltername}} with legalName
  replaceShelterName(text: string): string {
    if (!this.legalName) {
      this.logger.error('Legal name is not set, cannot replace shelter name.');
      return text;
    }
    const regex = new RegExp('{{sheltername}}', 'g');
    return text.replace(regex, this.legalName);
  }

  processContent(): void {
    if (this.legalName) {
      if (this.contentfulEntryData) {
        if (this.contentfulEntryData.title) {
          this.contentfulEntryData.title = this.replaceShelterName(
            this.contentfulEntryData.title
          );
        }
        if (this.contentfulEntryData.subtitle) {
          this.contentfulEntryData.subtitle = this.replaceShelterName(
            this.contentfulEntryData.subtitle
          );
        }
        if (this.bodyHtml) {
          this.bodyHtml = this.replaceShelterName(this.bodyHtml);
        }
        // Process ctaEndOfArticleImageAndText
        if (Array.isArray(this.ctaEndOfArticleImageAndText)) {
          this.ctaEndOfArticleImageAndText =
            this.ctaEndOfArticleImageAndText.map((ctaItem) => {
              if (ctaItem.ctaTextHtml) {
                ctaItem.ctaTextHtml = this.replaceShelterName(
                  ctaItem.ctaTextHtml
                );
              }
              return ctaItem;
            });
        }
        // Process ctaEndOfArticleImageAndText
        if (Array.isArray(this.ctaEndOfArticleProductAndService)) {
          this.ctaEndOfArticleProductAndService =
            this.ctaEndOfArticleProductAndService.map((ctaItem) => {
              if (ctaItem.ctaTextHtml) {
                ctaItem.ctaTextHtml = this.replaceShelterName(
                  ctaItem.ctaTextHtml
                );
              }
              return ctaItem;
            });
        }
        this.addClickTrackingToCTAs();

      }
    }
  }

  processCTAs(): void {
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.bodyHtml, 'text/html');

    this.processCTAClass(doc, 'ctaImageAndText');
    this.processCTAClass(doc, 'ctaProductAndService');

    // Update the bodyHtml with the processed content
    this.bodyHtml = doc.body.innerHTML;
    this.cdr.detectChanges();
  }

  processCTAClass(doc: Document, className: string): void {
    const ctaElements = Array.from(doc.getElementsByClassName(className));

    ctaElements.forEach((ctaElement) => {
      let shouldHideElement = false;

      // Check if any of the element's classes match a disabled brand
      shouldHideElement = Array.from(ctaElement.classList).some((classItem) =>
        this.disabledBrands.some(
          (disabledBrand) => classItem === disabledBrand.nationalBrandCmsTagId
        )
      );

      // Show or hide the element based on the flags set above
      if (shouldHideElement) {
        this.renderer.addClass(ctaElement, 'hidden');
      } else {
        this.renderer.removeClass(ctaElement, 'hidden');
      }
    });

    this.cdr.detectChanges();
  }

  checkDataAndProcessCTAs(): void {
    // Only process CTAs if both bodyHtml and brands are available
    if (this.bodyHtml && this.disabledBrands.length > 0) {
      this.processContent();
      this.processCTAs();
    }
  }

  fetchExclusiveBrands() {
    this.brand.getExclusive().subscribe(
      (response: any) => {
        // Categorize brands into Exclusive and Disabled
        if (this.daysSinceAdoption < this.shelterBrandingDays) {
          this.disabledBrands = response.filter(
            (brand: any) => brand.relationshipType === 'Disabled'
          );
        }

        // Call filterCTAs
        this.filterCTAs();
        this.processContent(); // Process any content replacements
        this.processCTAs(); // Now manipulate the DOM for CTA elements
        this.cdr.detectChanges();
      },
      (error) => {
        this.logger.error('Error fetching brands:', error);
      }
    );
  }

  filterCTAs() {
    // Filter CTAs by excluding those related to disabled brands
    this.filteredCTAs = this.filterCTAArray(this.ctaEndOfArticleImageAndText);
    this.filteredProductCTAs = this.filterCTAArray(
      this.ctaEndOfArticleProductAndService
    );

    this.replaceBottomProductsPTags();
    this.filterBottomProductsByLifeStage();
  }

  trackCTAClick(event: MouseEvent, ctaText: string, ctaLink: string, productTitle: string): void {
    const sanitizedCTAText = this.sanitizer.sanitize(1, ctaText) || 'Unknown CTA';
  
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'cta_click',
      cta_text: sanitizedCTAText,
      cta_link: ctaLink || 'Unknown Link',
      cta_product_name: productTitle || 'Unknown Product',
    });
  
    this.logger.log('CTA click tracked:', {
      ctaText: sanitizedCTAText,
      ctaLink,
      productTitle,
    });
  }  

  trackBodyHtmlCTAClick(ctaText: string, ctaLink: string): void {
    const ctaProductName = document.querySelector('.ctaProductAndService .cta_product_name')?.textContent?.trim() || 'Unknown Product';
  
    console.log('[trackBodyHtmlCTAClick] Called with:', {
      ctaText,
      ctaLink,
      ctaProductName,
    });
  
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'cta_click',
      cta_text: ctaText,
      cta_link: ctaLink,
      cta_product_name: ctaProductName,
    });
  
    console.log('[trackBodyHtmlCTAClick] Event pushed to dataLayer:', {
      event: 'cta_click',
      cta_text: ctaText,
      cta_link: ctaLink,
      cta_product_name: ctaProductName,
    });
  
    this.logger.log('[trackBodyHtmlCTAClick] Body HTML CTA click tracked:', {
      ctaText,
      ctaLink,
      ctaProductName,
    });
  }  

  addClickTrackingToCTAs(): void {
    if (!this.bodyHtmlContainer) {
      console.warn('[addClickTrackingToCTAs] bodyHtmlContainer not found');
      return;
    }
  
    // Grab the container
    const container = this.bodyHtmlContainer.nativeElement;
  
    // Find all CTA elements
    const ctaElements = container.querySelectorAll('.ctaProductAndService');
  
    // Iterate over each CTA element
    ctaElements.forEach((ctaElement: HTMLElement) => {
      const buttonElement = ctaElement.querySelector('input[type="button"]');
  
      if (!buttonElement) {
        console.error('[addClickTrackingToCTAs] Button element not found.');
        return;
      }
  
      // Use event delegation for better performance and to avoid multiple listeners
      this.bodyHtmlContainer.nativeElement.addEventListener('click', (event: Event) => {
        const button = (event.target as HTMLElement).closest('input[type="button"]');
        if (button) {
          const ctaElement = button.closest('.ctaProductAndService');
          const linkElement = ctaElement?.querySelector('a');
  
          // Prepare the data to send to GA4
          const ctaText = ctaElement?.textContent?.trim() || 'Unknown CTA';
          const ctaLink = linkElement?.getAttribute('href') || 'Unknown Link';
          const productTitle = ctaElement?.querySelector('.cta_product_name')?.textContent?.trim() || 'Unknown Product';
  
          // Push the data to the dataLayer
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'cta_click',
            cta_text: ctaText,
            cta_link: ctaLink,
            cta_product_name: productTitle,
          });
  
          // Clean, minimal logging
          console.log('GA4 Event:', {
            event: 'cta_click',
            cta_text: ctaText,
            cta_link: ctaLink,
            cta_product_name: productTitle,
          });
        }
      });
    });
  }  

  filterCTAArray(ctaArray: any[]): any[] {
    const filteredArray = ctaArray.filter((cta) => {
      // Exclude CTAs related to disabled brands

      const isCTARelatedToDisabled = cta.systemMetadata.tags.some(
        (tag: any) => {
          return this.disabledBrands.some(
            (brand) => tag.sys.id === brand.nationalBrandCmsTagId
          );
        }
      );

      if (isCTARelatedToDisabled) {
        return false;
      }

      return true;
    });

    return filteredArray;
  }

  printPage() {
    window.print();
  }

  getSafeHtml(): SafeHtml {
    let bodyString = this.filterInlineProductsByLifeStage();
    bodyString = this.replaceContent(bodyString);
    return this.sanitizer.bypassSecurityTrustHtml(bodyString);
  }

  fetchContentfulEntryData(petszelAnimalId: string): void {
    const idType = this.determineIdType(this.slug);
    this.contentfulService.getEntryById(this.slug, petszelAnimalId, idType).subscribe({
      next: (entryData: any) => {
        this.contentfulEntryData = entryData;
        this.processContent();
        if (entryData && entryData.ctaEndOfArticleProductAndService) {
          this.ctaEndOfArticleProductAndService =
            entryData.ctaEndOfArticleProductAndService;
        }
        // Use optional chaining to safely access the nested properties
        if (entryData && entryData.ctaEndOfArticleImageAndText) {
          this.ctaEndOfArticleImageAndText =
            entryData.ctaEndOfArticleImageAndText;
          entryData.ctaEndOfArticleImageAndText.forEach((cta: any) => {
            if (cta.systemMetadata && cta.systemMetadata.tags) {
              cta.systemMetadata.tags.forEach((tag: any) => {
                if (tag.sys && tag.sys.id) {
                }
              });
            }
          });
          this.fetchExclusiveBrands();
          this.shelterBrandingDays =
            this.branding.shelterBrandingDaysSubject.getValue();
        } else {
          this.logger.error('ctaEndOfArticleImageAndText not found in entryData');
        }

        // Fetch and display other data (e.g., articleData) as needed
        this.fetchOtherData();
        this.populateCardsData();
        this.isLoadingContentfulData = false;
        this.checkDataAndProcessCTAs();
        this.cdr.detectChanges();
      },
      error: () => {
        this.isLoadingContentfulData = false;
        this.showErrorBanner = true;
      }
    });
  }

  populateCardsData(): void {
    this.cardsData = []; // Initialize cardsData as an empty array to start with

    // Handle relevantArticlesRef
    if (
      this.contentfulEntryData &&
      Array.isArray(this.contentfulEntryData.relevantArticlesRef)
    ) {
      const articlesData = this.contentfulEntryData.relevantArticlesRef.map((articleRef: any) => ({
        type: 'article', // Added to distinguish between articles and videos
        title: articleRef.title || '',
        subtitle: articleRef.subtitle || '',
        image: articleRef.articleHeroImageSimple?.file?.url || '',
        entryId: articleRef.entryId || '',
        slug: articleRef.slug
      }));
      this.cardsData = this.cardsData.concat(articlesData);
    }

    // Handle relevantVideosRef
    if (
      this.contentfulEntryData &&
      Array.isArray(this.contentfulEntryData.relevantVideosRef)
    ) {
      const videosData = this.contentfulEntryData.relevantVideosRef.map((videoRef: any) => ({
        type: 'video', // Added to distinguish between videos and articles
        title: videoRef.videoName || '',
        subtitle: videoRef.videoDescription || '',
        video: videoRef.videoAssetSimple?.file?.url || '',
        entryId: videoRef.entryId || '',
        slug: videoRef.slug || '',
        videoLayout: videoRef.videoLayout,
        videoThumbnail: videoRef.videoThumbnailSimple.file.url,
        authorName: videoRef.videoAuthor?.publicauthorName || 'Unknown Author',
      }));
      this.cardsData = this.cardsData.concat(videosData);
    }

    // If no contentfulEntryData or both refs are missing or empty, you can handle it here
    if (!this.contentfulEntryData ||
        (!Array.isArray(this.contentfulEntryData.relevantArticlesRef) && !Array.isArray(this.contentfulEntryData.relevantVideosRef))) {
      this.logger.error('Both relevantArticlesRef and relevantVideosRef are undefined, null, or empty.');
      // Optionally, set this.cardsData to an empty array or handle it differently if needed
    }
  }

  scrollToTop(): void {
    document.documentElement.scrollTop = 0;
    // This works on Safari
    document.body.scrollTop = 0;
  }

  fetchOtherData(): void {
    const idType = this.determineIdType(this.slug);
    this.contentfulService.getContent(this.slug, idType).subscribe({
      next: (data: any) => {
        // Check if data.bodyHtml is present
        if (data.data.bodyHtml) {
          this.bodyHtml = this.processHtmlContent(data.data.bodyHtml); // Update the bodyHtml with the processed content
          this.checkDataAndProcessCTAs(); // Now call checkDataAndProcessCTAs
        } else {
          this.logger.error('bodyHtml is undefined or empty.');
          this.bodyHtml = ''; // Set it to an empty string or some default value
        }
      },
      error: err => {
        this.logger.error('Error in fetchOtherData:', err);
        this.showErrorBanner = true;
      }
    });
  }

  determineIdType(id: string): 'slug' | 'entry' {
    // Regex to check if the ID is in slug format (contains hyphens)
    const slugPattern = /-/;
    // If the ID contains hyphens, we assume it's a slugId, otherwise it's an entryId
    return slugPattern.test(id) ? 'slug' : 'entry';
  }

  processHtmlContent(rawHtml: string): string {
    // Process the HTML string as needed, for example:
    let processedHtml = rawHtml;

    // Replace {{sheltername}} with legalName
    if (this.legalName) {
      processedHtml = processedHtml.replace(/{{sheltername}}/g, this.legalName);
    }

    const parser = new DOMParser();
    let doc = parser.parseFromString(processedHtml, 'text/html');

    // Manipulate the parsed HTML here (e.g., adding classes to img or a tags)
    const imgElements = doc.querySelectorAll('img');
    imgElements.forEach((imgElement) => {
      if (imgElement.getAttribute('src') === '') {
        imgElement.classList.add('no-image');
      } else {
        imgElement.classList.add('custom-image-class');
      }
    });

    const aElements = doc.querySelectorAll('a');
    aElements.forEach((aElement) => {
      aElement.setAttribute('target', '_blank');
    });

    // Convert the manipulated HTML back to a string
    return doc.body.innerHTML;
  }

  listenToButtonClicks() {
    if (!this.bodyHtmlContainer) {
      return;
    }
    const container = this.bodyHtmlContainer.nativeElement;
    container.addEventListener('click', (event: any) => {
      const target = event.target;
      if (this.isTrackableButton(target)) {
        this.trackButtonClick(target);
      }
    });
  }

  private isTrackableButton(element: HTMLElement): boolean {
    // Here we check if the clicked element is an input of type button
    return (
      element.tagName.toLowerCase() === 'input' &&
      element.getAttribute('type') === 'button'
    );
  }

  private trackButtonClick(button: HTMLInputElement) {
    // Prepare the data you want to send to Google Analytics
    const eventData = {
      event_category: 'Button Click',
      event_label: button.value || 'Unknown Button',
    };

    this.analyticsService.trackEvent('button_click', eventData);
  }

  private filterBottomProductsByLifeStage(): void {
    if (this.filteredProductCTAs && this.filteredProductCTAs.length > 0) {
      this.filteredProductCTAs =  this.filteredProductCTAs.filter((product: any) => {
        return product.systemMetadata?.tags.find((tag: TagType) => {
          return this.petLifeStages.includes(tag.sys.id);
        });
      });
    }
  }

  private filterInlineProductsByLifeStage(): string {
    const doc = new DOMParser().parseFromString(this.bodyHtml, 'text/html');
    const products = doc.getElementsByClassName('ctaProductAndService');

    if (products?.length > 0) {
      Array.from(products).forEach((p) => {

        if (!p.classList.contains('hidden')) {
          let containsLifeStage = false;
          this.petLifeStages.forEach(stage => {
            if (p.classList.contains(stage)) {
              containsLifeStage = true;
            }
          });

          if (!containsLifeStage) {
            p.className = p.className + ' hidden';
          }
        }
      });
    }

    return doc.body.innerHTML;
  }

  private getPetInfo(): void {
    this.managePetInfoSubs = this.ownerFamilyService.getAnimalProfile(this.petId as string).subscribe((pet: any) => {
      this.petInfo = pet;
      const months = this.articleUtilsService.calculatePetAgeInMonths(pet.dateOfBirth);

      // Check for invalid date of birth and adjust categories accordingly
      if (pet.dateOfBirth === "0001-01-01T00:00:00" || isNaN(months)) {
        this.petLifeStages = [pet.animalType === 'Dog' ? 'speciesDogAll' : 'speciesCatAll'];
      } else {
        this.petLifeStages = this.articleUtilsService.getCategoriesByAgeAndType(months, pet.animalType);
      }

      this.fetchContentfulEntryData(this.petId as string);
    });
  }

  private replaceBottomProductsPTags(): void {
    this.filteredProductCTAs.map((product: any) => {
      product.ctaTextHtml = this.replaceContent(product.ctaTextHtml);
    });
  }

  private replaceContent(text: string): string {
    const firstNameRegex = new RegExp('{{firstname}}', 'g');
    const lastNameRegex = new RegExp('{{lastname}}', 'g');
    const animalNameRegex = new RegExp('{{animalname}}', 'g');
    const animalSpeciesRegex = new RegExp('{{animalspecies}}', 'g');
    const shelterNameRegex = new RegExp('{{sheltername}}', 'g');
    const shelterShortNameRegex = new RegExp('{{sheltershortname}}', 'g');
    const shelterTypeOrgRegex = new RegExp('{{shelterorgtypeshort}}', 'g');

    text = text.replace(firstNameRegex, this.ownerInfo.firstname);
    text = text.replace(lastNameRegex, this.ownerInfo.lastname);
    text = text.replace(animalNameRegex, this.petInfo.name);
    text = text.replace(animalSpeciesRegex, this.petInfo.animalType);
    text = text.replace(shelterNameRegex, this.shelterInfo.businessName);
    text = text.replace(shelterShortNameRegex, this.shelterInfo.shortName);
    text = text.replace(shelterTypeOrgRegex, this.shelterInfo.category);

    return text;
  }
}
